<template>
    <div class="home">
      <Header/>
      <div class="content"> 
        <div class="title">{{$t('m.wdsq')}}</div>
        
        <div class="card">
      <div class="titles">{{$t('m.wdyqlj')}}</div>
      <div class="box">
        <div class="link" v-if="userInfo.lastBookingTime!=0">{{codeWallet}}</div> 
        <div class="tips" v-if="userInfo.lastBookingTime==0">{{$t('m.swjryy')}}</div>        
      </div>  
      <div class="buttom" v-if="userInfo.lastBookingTime!=0">
        <button
            type="button"
            class="fuzhi btn act"
            v-clipboard:copy="codeWallet"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
          {{$t('m.fz')}}
          </button>     
      </div>   
      <div class="box">
        <div class="cont">
         <div class="ibox">
          <div class="txts">{{$t('m.wdtjdz')}}</div>
          <div class="rbox">
            <div class="txt">{{userInfo.referrer | yingcang}}</div>
          </div>
         </div>
        </div></div>  
     </div>

     <div class="card">
      <div class="titles" >{{$t('m.wddj')}}</div>
      <div class="box">
     
        <div class="cont">
         <div class="ibox flex" >
          <div class="txts">{{$t('m.sfdjd')}}</div>
          <div class="rbox">
            <div class="txt">{{userInfo.isBigNode?$t('m.shi'):$t('m.fou')}}</div>
          </div>
         </div>
        </div>

        <div class="cont">
         <div class="ibox flex" >
          <div class="txts">{{$t('m.sfxjd')}}</div>
          <div class="rbox">
            <div class="txt">{{userInfo.isSmallNode?$t('m.shi'):$t('m.fou')}}</div>
          </div>
         </div>
        </div>

        <div class="cont">
         <div class="ibox flex" >
          <div class="txts">{{$t('m.sffwzx')}}</div>
          <div class="rbox">
            <div class="txt">{{userInfo.isCenter?$t('m.shi'):$t('m.fou')}}</div>
          </div>
         </div>
        </div>
        <div class="cont">
         <div class="ibox flex" >
          <div class="txts">{{$t('m.yhdj')}}</div>
          <div class="rbox">
            <div class="txt">{{ userInfo.userLevel>0?'v'+userInfo.userLevel:'-/-' }} </div>
          </div>
         </div>
        </div>
        
      </div>  
     </div>
 <div class="card">
      <div class="titles">{{$t('m.sqyj')}}</div>
      <div class="box">
            <div class="cont">
         <div class="ibox flex" >
          <div class="txts">{{$t('m.gryj')}}</div>
          <div class="rbox">
            <div class="num">{{userInfo.totalInvest||0}}</div>
          </div>
         </div>
        </div>
        <div class="cont">
         <div class="ibox flex">
          <div class="txts">{{$t('m.sqyj')}}</div>
          <div class="rbox">
            <div class="num">{{userInfo.communityPerformance||0}}</div>
          </div>
         </div>
        </div>
        <div class="cont">
         <div class="ibox flex">
          <div class="txts">{{$t('m.xqyj')}}</div>
          <div class="rbox">
            <div class="num">{{userInfo.subCommunityPerformance||0}}</div>
          </div>
         </div>
        </div>
        
      </div>    
         <div class="buttom">
        <van-button
          :loading="loading"
          type="primary"
          class="btn act"
          @click="toPage('/partner')"
        >
         {{$t('m.wdfx')}}</van-button
        >
      </div>  
     </div>

    </div>
    <Bottom/>
</div>
</template>
<script>
import Header from '../components/header.vue';
import Bottom from '../components/bottom.vue';
import {getUser,getReferralsInfo} from "@/api/Public";
export default {
  name: "myContracts",
  components: {Header,Bottom},
  data() {
    return {
        current:0,
        codeWallet: "?code=" + this.$store.getters.userWallet,
        loading:false,
        list:[{show:false}],
        total:0,
        userInfo:""
    }
  },
  created(){
    this.getInfo()
    let baseUrl=((window.location.href).split("/#/"))[0]
    if(baseUrl.indexOf('?')){
        baseUrl= ((baseUrl).split("/?"))[0]
        this.codeWallet= baseUrl+"?code=" + this.$store.getters.userWallet   
    }else{
       this.codeWallet= baseUrl+"?code=" + this.$store.getters.userWallet
    }
  },
  filters: {
    yingcang(val) {
      if (val&&val!="0x0000000000000000000000000000000000000000") {
        let a = val.replace(/(\w{12})\w+(\w{12})/, "$1******$2");
        return a;
      } else {
        return "-/-";
      }
    },
  },
  methods:{
     getInfo() {
      let userWallet = this.$store.getters.userWallet
      if(userWallet){
        getUser(userWallet).then(res=>{
         this.userInfo=Object.assign({},res)        
        this.userInfo.totalInvest=(res.totalInvest/10**18).toFixed(4)
        this.userInfo.communityPerformance=(res.communityPerformance/10**18).toFixed(4) 
        this.userInfo.subCommunityPerformance=(res.subCommunityPerformance/10**18).toFixed(4) 
        })
        // getReferralsInfo(userWallet).then(res=>{
        //   this.total=res?res.length:0
        // })
      }
    },
    toshow(e,j){
      this.$set(this.list[e],'show',!j)
    },
    changer(e){
        this.current=e
    },
    onCopy() {
      this.$toast(this.$i18n.t("m.fzcg"));
    },
    onError() {
      this.$toast(this.$i18n.t("m.fzsb"));
    },
    async toPage(val) {    
     if(this.$store.getters.userWallet){
       this.$router.push({ path: val });
      }else{
        this.shows=true
      }
    },
  }
}

</script>
<style lang="scss" scoped>
@import "../../src/assets/scss/_handle.scss";

$t-mf: "PingFang";
$t-f: "DINAlternate-Bold";

.home {
  background:  #000000 url("../assets/img/pbg.png")  no-repeat;
  // background: #161628;
  background-size:contain;
  background-position: center bottom;
  overflow-x: hidden;
  width: 750px;
  margin: 0 auto;
  min-height: 100vh;
    .content{
        margin-top: 188px;
        .title{
      font-size: 38px;
      font-weight: bold;
      color: #e3cc86;
      margin: 72px auto;
      border-radius: 36px 0 36px 0;
      width: 360px;
      padding: 22px;
      position: relative;
    }
    .title::after{
      content: "";
      position: absolute;
      height: 4px;
      width: 100px;
      border-radius: 16px;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      background: linear-gradient(100deg, #dcc179 0, #603711 49%, #d5b96f 100%);
    }
        .card {
      // width: 694px;
      // background:#ffffff url("../assets/img/bgs.png") no-repeat ;
      background: #00000070;
         background-size: cover;
    background-position: left top;
          align-items: center;
          border: 1px solid #735f24;
      border-radius: 60px;
      min-height: 200px;
      padding: 30px 40px;
      margin: 20px 30px 20px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .titles{
        font-size: 28px;
          font-weight: 600;
          color: #ffffff;
          padding-bottom: 30px;
          width: 100%;
          border-bottom: 1px solid #735f24;
      }
      
      .box{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .link{
            margin:40px 26px 20px;
            font-size: 28px;
            white-space: normal;
            word-break: break-all;
            // background: linear-gradient(100deg, #d5b275 0%, #e6d69a 31%, #d9ad2a 49%, #c5a26c 79%, #a58b00 100%);
            background: linear-gradient(100deg, #d5b275 0%, #e6d69a 31%, #d9ad2a 49%, #c5a26c 79%, #a58b00 100%);
            // background: linear-gradient(to right, #C373FA, #8DBFEF); /*设置渐变的方向从左到右 颜色从ff0000到ffff00*/
				-webkit-background-clip: text;/*将设置的背景颜色限制在文字中*/
				-webkit-text-fill-color: transparent;/*给文字设置成透明*/
        }
        .tips{
           font-size: 24px;
           color: #ABABAB;
           margin: 40px auto 10px;
          }
          .line{
            width: 100%;
            height: 1px ;
            background: #353535;
            margin-top: 40px;
          }
        .cont{
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          .left{
            font-size: 24px;
            color: #FFFFFF;
            opacity: 0.6;
          }
          .right{
            .sinput{
              width:220px;
              border-radius: 100px;
              height: 64px;
              background: #2A2A2A;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              .txt{
                color: #717171;
                margin-right: 16px;
              }
            }
          }
          .input{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            background: #2A2A2A;
            border-radius: 100px;
            height: 64px;
            padding: 8px 20px 8px 30px;
            box-sizing: border-box;     
            margin: 32px 0;       
            .yinput{
              border: none;
              background: transparent;
              color: #ffffff;
              font-size: 28px;
              font-weight: bold;
            }
            .dw{
              display: flex;
              align-items: center;
              .tt{
                color: #ffffff;
                font-size: 28px;
                font-weight: bold;
                margin:  0 10px;                
                white-space: nowrap;
              }
              .ico{
                width: 40px;
                height: 40px;
              }
            }
          }
          .icos{
            font-size: 36px;
            color: #ffffff;
          }
          .rbox{
            display: flex;
            align-items: center;
            justify-content: center;
            .num{
          //  margin: 22px auto;
        font-size: 38px;
        font-family: $t-f;
        font-weight: bold;
          background: linear-gradient(100deg, #d5b275 0%, #e6d69a 31%, #d9ad2a 49%, #c5a26c 79%, #a58b00 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        }
            .txt{
              color: #ffffff;
                font-size: 24px;
                font-weight: bold;
                margin:  0 10px;
            }
            .ico{
              width: 40px;
              height: 40px;
            }
          }

         
          .ibox{
            border: 1px solid #735f24 ;
            background: linear-gradient(135.46deg, rgba(6, 6, 6, 0.5) 24.8%, rgba(32, 32, 32, 0.5) 57.07%);
            border-radius: 40px;
            width: 100%;
            margin-top: 40px;
            padding: 30px 40px;
            box-sizing: border-box;
       
            .txts{
              color: #ffffff;
              font-size: 24px;
              margin-bottom: 20px;
            }
          }
          .flex{
     display: flex;
    align-items: center;
    justify-content: space-between;
          }
          .btnr{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            .btn{
          background: #2A2A2A;
            color: #ABABAB;
            border: none;
            border-radius: 100px;
            height: 68px;
            width: 250px;
            font-size: 24px;
          }
        .act{
          color: #ffffff;
         box-shadow:inset 0px 4px 16px 4px #735f24;
          background: linear-gradient(100deg, #dcc179 0, #603711 49%, #d5b96f 100%);
          // background: linear-gradient(188.1deg, rgb(175, 57, 239) 18.52%, rgb(139, 61, 206) 71.67%);
        }
          }
          .btngroup{
            display: flex;
            align-items: center;
            margin-top: 20px;
            .btns{
              background: #2A2A2A;
              color: #ABABAB;
              border-radius: 56px;
              font-size: 28px;
              height: 52px;
              line-height: 52px;
              flex:1;
              border: 1px solid #2A2A2A;
            }
            .btn{
              background: transparent;
              color: #ffffff;
              border-radius: 56px;
              font-size: 28px;
              height: 52px;
              line-height: 52px;
              flex:1;
              border: 1px solid #735f24;
              margin-left: 20px;
            }
          }

        }
      }
      .buttom{
        display: flex;
        width: 100%;    
        margin: 30px auto; 
        .fuzhi {
        background: transparent;
        border: none;
      } 
        .btn{
         background: #2A2A2A;
          color: #ABABAB;
          border: none;
          border-radius: 100px;
          height: 68px;
          width: 100%;
          font-size: 24px;
        }
        .act{
          color: #ffffff;
          box-shadow:inset 0px 4px 16px 4px #735f24;
          background: linear-gradient(100deg, #dcc179 0, #603711 49%, #d5b96f 100%);
        }
      }
        
       

       .imggrop{
          display: flex;
          align-items: center;
          justify-content: center;
          .im1{
            width: 144px;
            height: 144px;
            z-index: 2;
            
          }.im2{
             width: 144px;
            height: 144px;
            margin-left: -60px;
            z-index: 1;
          }
        }
        .ttx{
          font-size: 36px;
          font-family: $t-f;
          font-weight: 600;
          color: #ffffff;
          line-height: 50px;
          text-align: center;
        }
      
        
      .sbot {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        flex: 1;
        width: 100%;
        text-align: center;
        margin: 24px 0;
          .txt {
            height: 56px;
            font-size: 46px;
            font-family: $t-mf;
            font-weight: 600;
            line-height: 54px;
            text-align: center;
            font-size: 56px;
            color: #ffffff;
          }
          .btn {
            background: #ffffff;
            // box-shadow: 0px 4px 16px 4px rgba(207,182,247,1);
            // border-radius: 40px;
            font-size: 32px;
            font-family: $t-f;
            font-weight: 600;
            color: #000000;
            line-height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            width: 280px;
height: 80px;
          }
      }
      .title{
        font-size: 36px;
font-family: $t-f;
font-weight: 600;
color: #000000;
line-height: 50px;
text-align: left;
      }
       .lines {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        flex: 1;
        width: 92%;
        text-align: center;
        margin: 37px auto;
        .item {
          text-align: center;
          flex: 1;
          
          .txt1 {
            height: 54px;
            font-size: 28px;
            font-family: $t-f;
            font-weight: bold;
            line-height: 54px;
            text-align: center;
            color: #000000;
            white-space: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
          }
          .txt2 {
            height: 40px;
            font-size: 28px;
            font-family: $t-mf;
            font-weight: 600;
            color: #888888;
            line-height: 40px;
            text-align: center;
            margin-top: 18px;
          }
        }
      }
    }
       
    }
   .mlist{
    background: #00000070;
         background-size: cover;
    background-position: left top;
          align-items: center;
          border: 1px solid #735f24;
      border-radius: 60px;
      min-height: 200px;
      padding: 30px 40px;
      margin: 80px 30px 20px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    .stitle{
        font-size: 28px;
          font-weight: 600;
          color: #ffffff;
          padding-bottom: 30px;
          width: 100%;
          border-bottom: 1px solid #735f24;
      }
    .list{
        height: 50vh;
        width: 100%;
        .item{
            border: 1px solid #735f24;
            margin-top: 43px;
            padding: 30px 40px 30px;
            border-radius: 40px;
            background: linear-gradient(135.46deg, rgba(6, 6, 6, 0.5) 24.8%, rgba(32, 32, 32, 0.5) 57.07%);
            .tops{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            .time{
              font-size: 26px;
              color: #ABABAB;
              max-width: 50%;
              overflow: hidden;
            }
            .status{
              border-radius: 56px;
              color:#ffffff;
              background: #2A2A2A;
              padding:10px 20px;
            }
          }
          .border{
        margin-top: 20px;
        border: 1px solid #735f24;
        padding: 16px;
        border-radius: 16px;
      }
          .cont{
            background: linear-gradient(135.46deg, rgba(6, 6, 6, 0.5) 24.8%, rgba(32, 32, 32, 0.5) 57.07%);
            .linex{
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              margin: 16px auto;
              .txt{
                font-size: 24px;
                color: #ffffff;
              }
              .num{
                        font-size: 28px;
        font-family: $t-f;
        font-weight: bold;
          background: linear-gradient(100deg, #d5b275 0%, #e6d69a 31%, #d9ad2a 49%, #c5a26c 79%, #a58b00 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    
        .dw{
          font-size: 22px;
          color: #12c2fc;
        }
              }
            }
            .box{
              display: flex;
              align-items: center;
              flex-direction: column;
              justify-content: center;
              border-radius: 16px;
              // background: #2a2a2a;
              background: transparent;
              border: 1px solid #28c0de;
              height: 120px;
              .stixl{
                font-size: 26px;
                color:#ABABAB;

              }
              .snum{
                 font-size: 32px;
                color:#ABABAB;
                margin-top: 16px;
              }
            }
          }
        }
        
    }
   }
}

  </style>
